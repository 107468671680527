import React from 'react';
import '../styles/TopBanner.scss';
class TopBanner extends React.Component {
  render() {
    const cauldronDark = require('../images/cauldron-dark.png');
    return (
      <div className={'commonSectionWrapper1'}>
        <div className={'container noPadd'}>
          <div className={'banner_container'}>
            <div className={'col-md-6 col-sm-12 col-xs-12 col-md-push-6 noPadd'}>
              <div className={'banner_text_container'}>
                <div className={'pageHeader'}>
                  Firebase2GraphQL
                </div>
                <div className={'pageDescription1'}>
                  Move from Firebase realtime db to instant GraphQL APIs on Postgres
                </div>
                <div className={'newBtn'}>
                  <a href={'https://github.com/hasura/graphql-engine/tree/master/community/tools/firebase2graphql'} target={'_blank'}><button>GET STARTED</button></a>
                </div>
              </div>
            </div>
            <div className={'col-md-6 col-sm-12 col-xs-12 col-md-pull-6 noPadd'}>
              <div className={'banner_image'}>
                <img className={'img-responsive'} src={cauldronDark} alt={'cauldron dark'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBanner;
