import React from 'react';
import '../styles/Styles.scss';
import TopBanner from '../components/TopBanner';
import PowerfulGraphQL from '../components/PowerfulGraphQL';
import EasySteps from '../components/EasySteps';
import Footer from '../components/Footer';
class Index extends React.Component {
  render() {
    return (
      <div className="wd100">
        <TopBanner/>
        <PowerfulGraphQL/>
        <EasySteps/>
        <Footer/>
      </div>
    );
  }
}


export default Index;
