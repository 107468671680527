import React from 'react';
import '../styles/Styles.scss';
class PowerfulGraphQL extends React.Component {
  render() {
    return (
      <div className={'lightGrayBgColor commonSectionWrapper'}>
        <div className={'container noPadd'}>
          <div className={'powerfulGraphQLWrapper'}>
            <div className={'col-md-6 col-sm-6 col-xs-12 noPadd'}>
              <div className={'pageSubHeader'}>
                Powerful GraphQL out-of-the-box
              </div>
              <div className={'pageDescription'}>
                Firebase2GraphQL is a CLI tool to that gives you instant realtime GraphQL on your Firebase data. It takes data exported from Firebase and imports into Postgres via Hasura GraphQL engine.
              </div>
            </div>
            <div className={'col-md-6 col-sm-6 col-xs-12 noPadd'}>
              <div className={'powerfulGraphQLImg'}>
                <img className={'img-responsive boxShadow'} src={'https://graphql-engine-cdn.hasura.io/assets/firebase2graphql/demo.gif'} alt={'Demo image'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PowerfulGraphQL;
