import React from 'react';
import '../styles/Styles.scss';

class EasySteps extends React.Component {
  render() {
    const hasura = require('../images/hasura.svg');
    const firebase = require('../images/firebase.svg');
    const postgresql = require('../images/postgresql.svg');
    // const graphql = require('../images/graphql.svg');
    return (
      <div className={'commonSectionWrapper'}>
        <div className={'container noPadd'}>
          <div className={'easyStepsWrapper'}>
            <div className={'pageSubHeader'}>
              3 easy steps to GraphQL
            </div>
            <div className={'clearBoth wd100 displayFlex'}>
              <div className={'col-md-12 col-sm-12 col-xs-12 noPadd'}>
                <div className={'stepsListWrapper'}>
                  <div className={'col-md-4 col-sm-4 col-xs-12 noPadd'}>
                    <div className={'stepsList'}>
                      <div className={'stepsIcon'}>
                        <img className={'img-responsive'} src={hasura} alt={'Hasura icon'}/>
                      </div>
                      <div className={'pageDescription'}>
                        1. Deploy Hasura GraphQL Engine to Heroku
                      </div>
                    </div>
                  </div>
                  <div className={'col-md-4 col-sm-4 col-xs-12 noPadd'}>
                    <div className={'stepsList'}>
                      <div className={'stepsIcon'}>
                        <img className={'img-responsive'} src={firebase} alt={'Firebase icon'}/>
                      </div>
                      <div className={'pageDescription'}>
                        2. Export JSON from the Firebase Console
                      </div>
                    </div>
                  </div>
                  <div className={'col-md-4 col-sm-4 col-xs-12 noPadd'}>
                    <div className={'stepsList'}>
                      <div className={'stepsIcon'}>
                        <img className={'img-responsive'} src={postgresql} alt={'Postgres icon'}/>
                      </div>
                      <div className={'pageDescription'}>
                        3. Use the CLI to import the data into Postgres
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*
              <div className={'col-md-4 col-sm-5 col-xs-12'}>
                <div className={'easyStepsImg boxShadow'}>
                  <img className={'img-responsive'} src={graphql} alt={'Postgres icon'}/>
                  <div className={'pageDescription'}>
                    And start querying your data in GraphQL!
                  </div>
                </div>
              </div>
              */}
            </div>
            <div className={'commonBtn'}>
              <a href={'https://github.com/hasura/graphql-engine/tree/master/community/tools/firebase2graphql'} target={'_blank'}><button>Start querying in GraphQL</button></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EasySteps;
